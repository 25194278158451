<div class="progress">
    <div
      class="progress-bar"
      role="progressbar"
      [style.width.%]="progressValue"
      aria-valuenow="100"
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  </div>
  