import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.css'],
    standalone: true
})
export class ProgressBarComponent implements OnInit {
  @Input() public progressValue: number;

  constructor() { }

  ngOnInit(): void {
  }

}
